import { default as _91slug_93E3KgjCflWXMeta } from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/pages/[slug].vue?macro=true";
import { default as bedankthuezGge1v4Meta } from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/pages/bedankt.vue?macro=true";
import { default as contactjLW1I6gQ6uMeta } from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/pages/contact.vue?macro=true";
import { default as indexQY7BeUb7JuMeta } from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/pages/index.vue?macro=true";
import { default as open_45sollicitatiehx5wlU9cWnMeta } from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/pages/open-sollicitatie.vue?macro=true";
import { default as over_45onsOps3ClMOOKMeta } from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/pages/over-ons.vue?macro=true";
import { default as vacatures_45als_45_91slug_93vNK96mthEzMeta } from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/pages/vacatures-als-[slug].vue?macro=true";
import { default as vacatures_45bij_45_91slug_93Zas1Li70jjMeta } from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/pages/vacatures-bij-[slug].vue?macro=true";
import { default as vacatures_45in_45_91slug_93oINHKBHN9jMeta } from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/pages/vacatures-in-[slug].vue?macro=true";
import { default as _91slug_93YHvDsqiblYMeta } from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/pages/vacatures/[slug].vue?macro=true";
import { default as indexOwZSfhuXjoMeta } from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/pages/vacatures/index.vue?macro=true";
import { default as werkgevers3XmHK5ltq5Meta } from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/pages/werkgevers.vue?macro=true";
export default [
  {
    name: _91slug_93E3KgjCflWXMeta?.name ?? "slug",
    path: _91slug_93E3KgjCflWXMeta?.path ?? "/:slug()",
    meta: _91slug_93E3KgjCflWXMeta || {},
    alias: _91slug_93E3KgjCflWXMeta?.alias || [],
    redirect: _91slug_93E3KgjCflWXMeta?.redirect,
    component: () => import("/data/www/nextrecruitment.nl/next-recruitment/nuxt/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: bedankthuezGge1v4Meta?.name ?? "bedankt",
    path: bedankthuezGge1v4Meta?.path ?? "/bedankt",
    meta: bedankthuezGge1v4Meta || {},
    alias: bedankthuezGge1v4Meta?.alias || [],
    redirect: bedankthuezGge1v4Meta?.redirect,
    component: () => import("/data/www/nextrecruitment.nl/next-recruitment/nuxt/pages/bedankt.vue").then(m => m.default || m)
  },
  {
    name: contactjLW1I6gQ6uMeta?.name ?? "contact",
    path: contactjLW1I6gQ6uMeta?.path ?? "/contact",
    meta: contactjLW1I6gQ6uMeta || {},
    alias: contactjLW1I6gQ6uMeta?.alias || [],
    redirect: contactjLW1I6gQ6uMeta?.redirect,
    component: () => import("/data/www/nextrecruitment.nl/next-recruitment/nuxt/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: indexQY7BeUb7JuMeta?.name ?? "index",
    path: indexQY7BeUb7JuMeta?.path ?? "/",
    meta: indexQY7BeUb7JuMeta || {},
    alias: indexQY7BeUb7JuMeta?.alias || [],
    redirect: indexQY7BeUb7JuMeta?.redirect,
    component: () => import("/data/www/nextrecruitment.nl/next-recruitment/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: open_45sollicitatiehx5wlU9cWnMeta?.name ?? "open-sollicitatie",
    path: open_45sollicitatiehx5wlU9cWnMeta?.path ?? "/open-sollicitatie",
    meta: open_45sollicitatiehx5wlU9cWnMeta || {},
    alias: open_45sollicitatiehx5wlU9cWnMeta?.alias || [],
    redirect: open_45sollicitatiehx5wlU9cWnMeta?.redirect,
    component: () => import("/data/www/nextrecruitment.nl/next-recruitment/nuxt/pages/open-sollicitatie.vue").then(m => m.default || m)
  },
  {
    name: over_45onsOps3ClMOOKMeta?.name ?? "over-ons",
    path: over_45onsOps3ClMOOKMeta?.path ?? "/over-ons",
    meta: over_45onsOps3ClMOOKMeta || {},
    alias: over_45onsOps3ClMOOKMeta?.alias || [],
    redirect: over_45onsOps3ClMOOKMeta?.redirect,
    component: () => import("/data/www/nextrecruitment.nl/next-recruitment/nuxt/pages/over-ons.vue").then(m => m.default || m)
  },
  {
    name: vacatures_45als_45_91slug_93vNK96mthEzMeta?.name ?? "vacatures-als-slug",
    path: vacatures_45als_45_91slug_93vNK96mthEzMeta?.path ?? "/vacatures-als-:slug()",
    meta: vacatures_45als_45_91slug_93vNK96mthEzMeta || {},
    alias: vacatures_45als_45_91slug_93vNK96mthEzMeta?.alias || [],
    redirect: vacatures_45als_45_91slug_93vNK96mthEzMeta?.redirect,
    component: () => import("/data/www/nextrecruitment.nl/next-recruitment/nuxt/pages/vacatures-als-[slug].vue").then(m => m.default || m)
  },
  {
    name: vacatures_45bij_45_91slug_93Zas1Li70jjMeta?.name ?? "vacatures-bij-slug",
    path: vacatures_45bij_45_91slug_93Zas1Li70jjMeta?.path ?? "/vacatures-bij-:slug()",
    meta: vacatures_45bij_45_91slug_93Zas1Li70jjMeta || {},
    alias: vacatures_45bij_45_91slug_93Zas1Li70jjMeta?.alias || [],
    redirect: vacatures_45bij_45_91slug_93Zas1Li70jjMeta?.redirect,
    component: () => import("/data/www/nextrecruitment.nl/next-recruitment/nuxt/pages/vacatures-bij-[slug].vue").then(m => m.default || m)
  },
  {
    name: vacatures_45in_45_91slug_93oINHKBHN9jMeta?.name ?? "vacatures-in-slug",
    path: vacatures_45in_45_91slug_93oINHKBHN9jMeta?.path ?? "/vacatures-in-:slug()",
    meta: vacatures_45in_45_91slug_93oINHKBHN9jMeta || {},
    alias: vacatures_45in_45_91slug_93oINHKBHN9jMeta?.alias || [],
    redirect: vacatures_45in_45_91slug_93oINHKBHN9jMeta?.redirect,
    component: () => import("/data/www/nextrecruitment.nl/next-recruitment/nuxt/pages/vacatures-in-[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YHvDsqiblYMeta?.name ?? "vacatures-slug",
    path: _91slug_93YHvDsqiblYMeta?.path ?? "/vacatures/:slug()",
    meta: _91slug_93YHvDsqiblYMeta || {},
    alias: _91slug_93YHvDsqiblYMeta?.alias || [],
    redirect: _91slug_93YHvDsqiblYMeta?.redirect,
    component: () => import("/data/www/nextrecruitment.nl/next-recruitment/nuxt/pages/vacatures/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexOwZSfhuXjoMeta?.name ?? "vacatures",
    path: indexOwZSfhuXjoMeta?.path ?? "/vacatures",
    meta: indexOwZSfhuXjoMeta || {},
    alias: indexOwZSfhuXjoMeta?.alias || [],
    redirect: indexOwZSfhuXjoMeta?.redirect,
    component: () => import("/data/www/nextrecruitment.nl/next-recruitment/nuxt/pages/vacatures/index.vue").then(m => m.default || m)
  },
  {
    name: werkgevers3XmHK5ltq5Meta?.name ?? "werkgevers",
    path: werkgevers3XmHK5ltq5Meta?.path ?? "/werkgevers",
    meta: werkgevers3XmHK5ltq5Meta || {},
    alias: werkgevers3XmHK5ltq5Meta?.alias || [],
    redirect: werkgevers3XmHK5ltq5Meta?.redirect,
    component: () => import("/data/www/nextrecruitment.nl/next-recruitment/nuxt/pages/werkgevers.vue").then(m => m.default || m)
  }
]