import revive_payload_client_Mi0XSnFgql from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.2_eslint@8.57.0_floating-vue@5.2.2_ro_l2yz53darwyp4cuwk2v6553dia/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_8GgmThAz6l from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.2_eslint@8.57.0_floating-vue@5.2.2_ro_l2yz53darwyp4cuwk2v6553dia/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_h4LmsHKLvD from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.2_eslint@8.57.0_floating-vue@5.2.2_ro_l2yz53darwyp4cuwk2v6553dia/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_SDlu5ynPi5 from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.3.1_@vue+compiler-core@3.4.27_nuxt@3.11.2_postcss@8._ib3qaerio4jmeo2z5xc34vzasm/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_ekNQ8cIxUv from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.2_eslint@8.57.0_floating-vue@5.2.2_ro_l2yz53darwyp4cuwk2v6553dia/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_Gx5S34Yv84 from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.2_eslint@8.57.0_floating-vue@5.2.2_ro_l2yz53darwyp4cuwk2v6553dia/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_JiBfg2NyW0 from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.2_eslint@8.57.0_floating-vue@5.2.2_ro_l2yz53darwyp4cuwk2v6553dia/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_rW6hAIoeY5 from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/node_modules/.pnpm/nuxt-bugsnag@7.3.0_rollup@4.17.2/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import i18n_6zDN12cEjr from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.17.2_vue@3.4.27/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import formkitPlugin_pZqjah0RUG from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/.nuxt/formkitPlugin.mjs";
import plugin_443eo3YktE from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-module-apollo@1.0.1_rollup@4.17.2_typescript@5.4.5_vue@3.4.27/node_modules/@bttr-devs/nuxt-module-apollo/dist/runtime/plugin.mjs";
import chunk_reload_client_DaoxQhBvCl from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.2_eslint@8.57.0_floating-vue@5.2.2_ro_l2yz53darwyp4cuwk2v6553dia/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import errors_Gt6gsmBGpW from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.3.1_@unhead+shared@1.9.10_@vue+compiler-cor_tmhwyohk6zm42qjwyhrow5pqoa/node_modules/@bttr-devs/nuxt-layer-base/plugins/errors.ts";
import i18n_fYTD1MHvTM from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.3.1_@unhead+shared@1.9.10_@vue+compiler-cor_tmhwyohk6zm42qjwyhrow5pqoa/node_modules/@bttr-devs/nuxt-layer-base/plugins/i18n.ts";
import apollo_E9jur6VcPJ from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.0.2_@nuxt+devtools@1.3.1_@unhead+shared@1.9.10_@vue+compiler-co_hsa5fez4mcslo4vn4nf4tfnxqe/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/apollo.ts";
import body_scroll_lock_hNSArcroyK from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/plugins/body-scroll-lock.ts";
import dynamic_vw_client_kS7b5RjaKG from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/plugins/dynamic-vw.client.ts";
import vue_gtag_client_CYt6toK02h from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/plugins/vue-gtag.client.ts";
export default [
  revive_payload_client_Mi0XSnFgql,
  unhead_8GgmThAz6l,
  router_h4LmsHKLvD,
  _0_siteConfig_SDlu5ynPi5,
  payload_client_ekNQ8cIxUv,
  check_outdated_build_client_Gx5S34Yv84,
  components_plugin_KR1HBZs4kY,
  prefetch_client_JiBfg2NyW0,
  plugin_rW6hAIoeY5,
  i18n_6zDN12cEjr,
  formkitPlugin_pZqjah0RUG,
  plugin_443eo3YktE,
  chunk_reload_client_DaoxQhBvCl,
  errors_Gt6gsmBGpW,
  i18n_fYTD1MHvTM,
  apollo_E9jur6VcPJ,
  body_scroll_lock_hNSArcroyK,
  dynamic_vw_client_kS7b5RjaKG,
  vue_gtag_client_CYt6toK02h
]