<template>
  <NuxtLayout name="dark">
    <div class="relative mx-auto flex h-[18rem] w-[18rem] items-center justify-center lg:h-[37.5rem] lg:w-[37.5rem]">
      <img
        src="~/assets/img/next-recruitment-beeldmerk-gradient-zwart.svg"
        class="absolute inset-0 h-[18rem] w-[18rem] lg:h-[37.5rem] lg:w-[37.5rem]"
        alt="Placeholder"
      >
      <div class="z-20 flex max-w-xs flex-col items-center gap-10 text-center text-white">
        <h1 class="font-technor text-5xl leading-none lg:text-9xl">
          {{ error.statusCode }}
        </h1>
        <p class="font-satoshi lg:text-2xl">
          {{ error.statusCode === 404 ? `De pagina die je probeert te bezoeken bestaat helaas niet (meer).` : error.message }}
        </p>
        <UiLinkNavigation @click="clearError({redirect: '/'})">
          Naar de homepage
        </UiLinkNavigation>
      </div>
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
defineProps<{
  error: {
    message: string
    statusCode: number
  },
}>();
useHead({
  title: 'Pagina niet gevonden',
});
</script>
