<template>
  <NuxtLayout
    :name="layout"
    class="overflow-clip"
  >
    <NuxtPage id="page" />
  </NuxtLayout>
</template>

<script setup lang="ts">
import emitter from 'tiny-emitter/instance';

const route = useRoute();
const page = useSofiePageWhenAvailable();

const pageTitle = computed(() => page.value?.title ?? '');

const lightLayoutPages = ['contact', 'werkgevers', 'vragen'];

const layout = computed(() => {
  if (route.meta.page?.template_name === 'TemplateUitgebreid') {
    return 'extended';
  } else if (lightLayoutPages.includes(route.name)) {
    return 'light';
  } else {
    return 'default';
  }
});

useHead({
  title: pageTitle,
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - Next Recruitment` : 'Next Recruitment';
  },
  meta: [
    {name: 'og:site_name', content: 'Next Recruitment'},
    {name: 'og:image', content: '/icon.png'},
    {name: 'og:description', content: 'Bij Next Recruitment gaan we uit van menskracht, jouw menskracht! Want daar zit het verschil.'},
  ],
  link: [
    {rel: 'stylesheet', href: 'https://use.typekit.net/qrw2yuj.css'},
  ],
});

const color = ref('#7270C2');
useRouter().beforeResolve((to, from) => {
  if (to.path !== from.path) {
    color.value = '#7270C2';
  }
});

// TODO: use some sort of page meta instead of dynamic style component
emitter.on('global:set-color', (newColor: string) => {
  // Only change it if the new color isn't null, otherwise keep default violet color
  if (newColor) {
    color.value = newColor;
  }
});
</script>

<style>
#page {
  --dynamic: v-bind(color);
}
</style>
