import validate from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.2_eslint@8.57.0_floating-vue@5.2.2_ro_l2yz53darwyp4cuwk2v6553dia/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45trailing_45slash_45global from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.3.1_@unhead+shared@1.9.10_@vue+compiler-cor_tmhwyohk6zm42qjwyhrow5pqoa/node_modules/@bttr-devs/nuxt-layer-base/middleware/redirect-trailing-slash.global.ts";
import manifest_45route_45rule from "/data/www/nextrecruitment.nl/next-recruitment/nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.2_eslint@8.57.0_floating-vue@5.2.2_ro_l2yz53darwyp4cuwk2v6553dia/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "sofie-page": () => import("/data/www/nextrecruitment.nl/next-recruitment/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.0.2_@nuxt+devtools@1.3.1_@unhead+shared@1.9.10_@vue+compiler-co_hsa5fez4mcslo4vn4nf4tfnxqe/node_modules/@bttr-devs/nuxt-layer-sofie/middleware/sofie-page.ts")
}