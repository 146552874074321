import {nl} from '@formkit/i18n';
import {createConfig} from '@formkit/core';
import {generateClasses} from '@formkit/themes';

export default createConfig({
 locales: {nl},
 locale: 'nl',
 icons:{
  checkboxDecorator: '<svg viewBox="0 0 22 16" xmlns="http://www.w3.org/2000/svg"><path d="M21.1297 0.828115C21.6078 1.26874 21.6078 1.98124 21.1297 2.37968L8.75469 14.7547C8.35625 15.2328 7.64375 15.2328 7.20312 14.7547L0.829484 8.37968C0.390172 7.98124 0.390172 7.26874 0.829484 6.82812C1.26875 6.39218 1.98125 6.39218 2.42047 6.82812L8 12.4109L19.5781 0.828115C20.0187 0.390303 20.7313 0.390303 21.1297 0.828115Z" fill="currentColor"/></svg>',
 },
 config: {
  classes: generateClasses({
   // Global styles apply to _all_ inputs with matching section keys
   global: {
    fieldset: 'border border-neutral-400 rounded-lg px-2 pb-1',
    help: 'text-xs text-neutral-500',
    inner: 'formkit-disabled:bg-neutral-200 formkit-disabled:cursor-not-allowed formkit-disabled:pointer-events-none',
    input: 'appearance-none focus:outline-none focus:ring-0 focus:shadow-none',
    label: 'font-technor block mb-2 font-medium text-lg',
    legend: 'font-bold text-sm',
    loaderIcon: 'inline-flex items-center w-4 text-neutral-600 animate-spin',
    message: 'text-red-500 mb-1 text-xs',
    messages: 'list-none p-0 mt-1 mb-0',
    outer: 'formkit-disabled:opacity-50 mb-3',
    prefixIcon: 'w-10 flex self-stretch grow-0 shrink-0 rounded-tl rounded-bl border-r border-neutral-400 bg-white bg-gradient-to-b from-transparent to-neutral-200 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
    suffixIcon: 'w-7 pr-3 p-3 flex self-stretch grow-0 shrink-0 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
   },

   // Family styles apply to all inputs that share a common family
   'family:box': {
    decorator: 'block relative h-8 w-8 mr-2 rounded-lg bg-white ring-1 ring-neutral-300 text-transparent peer-checked:text-primary peer-focus-within:border-grey group-hover:border-grey transition ',
    decoratorIcon: 'flex p-1 w-full h-full',
    help: 'mb-2 mt-1.5',
    input: 'absolute w-0 h-0 overflow-hidden opacity-0 pointer-events-none peer',
    inner: '$reset',
    label: '$reset text-base text-neutral-900 mt-0.5 font-satoshi select-none',
    wrapper: 'flex items-center mb-2 group cursor-pointer',
    legend: '$reset text-lg font-lato mb-2 font-medium text-grey',
   },
   'family:button': {
    input: '$reset inline-flex font-medium text-lg items-center bg-primary p-5 rounded-full focus-visible:outline-2 focus-visible:outline-blue-600 focus-visible:outline-offset-2 formkit-disabled:bg-neutral-400 formkit-loading:before:w-4 formkit-loading:before:h-4 formkit-loading:before:mr-2 formkit-loading:before:border formkit-loading:before:border-2 formkit-loading:before:border-r-transparent formkit-loading:before:rounded-3xl formkit-loading:before:border-white formkit-loading:before:animate-spin',
    wrapper: 'my-4',
    prefixIcon: '$reset block w-4 -ml-2 mr-2 stretch',
    suffixIcon: '$reset block w-4 ml-2 stretch',
   },
   'family:dropdown': {
    dropdownWrapper: 'my-2 w-full shadow-lg rounded-lg [&::-webkit-scrollbar]:hidden',
    emptyMessageInner: 'flex items-center justify-center text-sm p-2 text-center w-full text-neutral-500 [&>span]:mr-3 [&>span]:ml-0',
    inner: ' relative flex ring-1 ring-neutral-400 focus-within:ring-neutral-700 hover:ring-neutral-500 focus-within:ring-2 rounded-lg mb-1 formkit-disabled:focus-within:ring-neutral-400 formkit-disabled:focus-within:ring-1 [&>span:first-child]:focus-within:text-blue-500',
    input: 'w-full px-3 py-2',
    listbox: 'bg-white shadow-lg rounded-lg overflow-hidden',
    listboxButton: 'flex w-12 self-stretch justify-center mx-auto',
    listitem: 'pl-7 relative hover:bg-neutral-300 data-[is-active="true"]:bg-neutral-300 aria-selected:bg-blue-600 aria-selected:text-white data-[is-active="true"]:aria-selected:bg-blue-600 data-[is-active="true"]:aria-selected:bg-blue-700',
    loaderIcon: 'ml-auto',
    loadMoreInner: 'flex items-center justify-center text-sm p-2 text-center w-full text-blue-500 formkit-loading:text-neutral-500 cursor-pointer [&>span]:mr-3 [&>span]:ml-0',
    option: 'p-2.5',
    optionLoading: 'pl-2.5 text-neutral-400',
    placeholder: 'p-2.5 text-neutral-400',
    selector: 'flex w-full justify-between items-center min-h-[2.625em] [&u] cursor-default',
    selection: 'flex w-full',
    selectedIcon: 'block absolute top-1/2 left-2 w-3 -translate-y-1/2',
    selectIcon: 'flex box-content w-4 px-2 self-stretch grow-0 shrink-0 [&>svg]:w-[1em] cursor-pointer',
   },
   'family:text': {
    inner: 'flex items-center rounded-lg ring-1 ring-neutral-300 focus-within:ring-neutral-700 hover:ring-neutral-500 focus-within:ring-2 [&>label:first-child]:focus-within:text-blue-500 rounded-lg mb-1',
    input: 'w-full p-4 border-none text-base rounded-lg text-neutral-700 placeholder-neutral-400',
   },
   'family:date': {
    inner: 'flex items-center ring-1 ring-neutral-400 focus-within:ring-neutral-700 hover:ring-neutral-500 focus-within:ring-2 [&>label:first-child]:focus-within:text-blue-500 rounded-lg mb-1',
    input: 'w-full px-3 py-2 border-none text-neutral-700 placeholder-neutral-400',
   },
   name: {
    label: 'hidden',
    inner: 'border-none',
   },
   // Specific styles apply only to a given input type
   color: {
    inner: 'flex max-w-[5.5em] w-full formkit-prefix-icon:max-w-[7.5em] formkit-suffix-icon:formkit-prefix-icon:max-w-[10em]',
    input: '$reset appearance-none w-full cursor-pointer border-none rounded-lg p-0 m-0 bg-transparent [&::-webkit-color-swatch-wrapper]:p-0 [&::-webkit-color-swatch]:border-none',
    suffixIcon: 'min-w-[2.5em] pr-0 pl-0 m-auto',
   },
   file: {
    fileItem: 'flex items-center text-neutral-800 mb-1 last:mb-0',
    fileItemIcon: 'w-4 mr-2 shrink-0',
    fileList: 'shrink grow peer !p-4 formkit-multiple:data-[has-multiple="true"]:mb-6',
    fileName: 'break-all grow text-ellipsis',
    fileRemove: 'relative z-[2] ml-auto text-[0px] hover:text-red-500 pl-2 peer-data-[has-multiple=true]:text-sm peer-data-[has-multiple=true]:text-blue-500 peer-data-[has-multiple=true]:ml-3 peer-data-[has-multiple=true]:mb-2 formkit-multiple:bottom-[0.15em] formkit-multiple:pl-0 formkit-multiple:ml-0 formkit-multiple:left-[1em] formkit-multiple:formkit-prefix-icon:left-[3.75em]',
    fileRemoveIcon: 'block text-base w-3 relative z-[2]',
    inner: 'relative h-16 !max-w-full cursor-pointer !ring-black formkit-multiple:[&>button]:absolute',
    input: 'cursor-pointer text-transparent absolute top-0 right-0 left-0 bottom-0 opacity-0 z-[2]',
    noFiles: 'flex w-full justify-center px-4 py-2 text-black',
    noFilesIcon: 'w-4 mr-2',
   },
   radio: {
    decorator: 'rounded-full',
    decoratorIcon: 'w-5 p-[5px]',
   },
   range: {
    inner: '$reset flex items-center ',
    input: '$reset w-full mb-1 h-2 p-0 rounded-full',
    prefixIcon: '$reset w-4 mr-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
    suffixIcon: '$reset w-4 ml-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
   },
   select: {
    inner: 'flex relative items-center rounded-lg mb-1 ring-1 ring-neutral-300 focus-within:ring-neutral-700 hover:ring-neutral-500 focus-within:ring-2 [&>span:first-child]:focus-within:text-blue-500',
    input: 'w-full rounded-lg pr-8 p-4 border-none text-base text-neutral-700 placeholder-neutral-400 formkit-multiple:p-0 data-[placeholder="true"]:text-neutral-400 formkit-multiple:data-[placeholder="true"]:text-inherit',
    selectIcon: 'flex p-[3px] shrink-0 w-5 mr-2 -ml-[1.5em] h-full pointer-events-none [&>svg]:w-[1em]',
    option: 'formkit-multiple:p-3 formkit-multiple:text-sm text-neutral-700',
   },
   textarea: {
    inner: 'flex rounded-lg mb-1 ring-1 ring-neutral-300 focus-within:ring-neutral-700 hover:ring-neutral-500 [&>label:first-child]:focus-within:text-blue-500',
    input: 'block rounded-lg w-full h-32 px-3 py-3 border-none text-base text-neutral-700 placeholder-neutral-400 focus:shadow-outline',
   },
  }),
 },
});
